import Ask from "../components/Ask";
import Navbar from "../components/Navbar";
import PrevQues from "../components/PrevQues";
import { useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from "react";
import languageContext from "../context/language/languageContext";



const QuestionPage = () => {
    const { bookappointment, isLeftPanel, setIsLeftPanel } = useContext(languageContext);
    console.log(bookappointment)
    // const bookAppointment = location.state?.book_appointment;
    // const  Checklist= location.state?.Checklist;

    useEffect(()=>{
        const resizeHandler = () => setIsLeftPanel(false);
        window.addEventListener("resize", resizeHandler);

        return ()=>{
            window.removeEventListener("resize", resizeHandler);
        }
    },[])

    return (
        <div className="flex flex-col h-[100vh] "> {/* Ensure full screen height */}
            <Navbar />
            <div className="flex h-full  relative" style={{ overflow: 'hidden' }}> {/* Ensure remaining space fills the screen */}
                <div className="chatHistory  w-3/12 overflow-y-auto"> {/* Make this section scrollable */}
                    <PrevQues />
                </div>

                {isLeftPanel && <div className="bg-slate-200 z-10 w-6/12 overflow-y-auto  absolute top-0 left-0 max-h-screen"> {/* Make this section scrollable */}
                    <PrevQues />
                </div>}

                <div className="w-full overflow-y-auto"> {/* Make this section scrollable */}
                    <Ask bookappointment={bookappointment}/>
                </div>
            </div>
        </div>
    );
}

export default QuestionPage;
